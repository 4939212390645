import {supabase} from "../../../services/Supabase";

export const GroupSchedulesService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('schedules_groups')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('schedules_groups')
            .select('*')
        return data
    },

    create: async function(schedule_group: any) {
        const { data, error } = await supabase
            .from('schedules_groups')
            .insert([
                { id: schedule_group.id },
            ])
        return data
    },

    update: async function(id: string, schedule_group: any) {
        const { data, error } = await supabase
            .from('schedules_groups')
            .update([
                { ...schedule_group },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('schedules_groups')
            .delete()
            .eq('id', id)
        return data
    }
}

export const LocalSchedulesService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('schedules_local')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('schedules_local')
            .select('*')
        return data
    },

    create: async function(schedule_local: any) {
        const { data, error } = await supabase
            .from('schedules_local')
            .insert([
                { id: schedule_local.id },
            ])
        return data
    },

    update: async function(id: string, schedule_local: any) {
        const { data, error } = await supabase
            .from('schedules_local')
            .update([
                { ...schedule_local },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('schedules_local')
            .delete()
            .eq('id', id)
        return data
    }
}

export const ExceptionScheduleService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('schedules_exceptions')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('schedules_exceptions')
            .select('*')
        return data
    },

    create: async function(schedules_exception: any) {
        const { data, error } = await supabase
            .from('schedules_exceptions')
            .insert([
                { id: schedules_exception.id },
            ])
        return data
    },

    update: async function(id: string, schedules_exception: any) {
        const { data, error } = await supabase
            .from('schedules_exceptions')
            .update([
                { ...schedules_exception },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('schedules_exceptions')
            .delete()
            .eq('id', id)
        return data
    }
}