import {supabase} from "./Supabase";

export const AuthService = {
    login: async function (username: string, password: string) {
        let { data, error } = await supabase.auth.signInWithPassword({
            email: username,
            password: password
        })

        return data
    },
    
    register: async function (username: string, password: string) {
        let { data, error } = await supabase.auth.signUp({
            email: username,
            password: password
        })

        return data
    },

    logout: async function () {
        const { error } = await supabase.auth.signOut()
    }
}
