import {useEffect, useState} from "react";
import {GraphicTemplatesService} from "../services/graphics";

function Template(data: any) {
    const template = data.template
    return <div>
        templates_id: {template.id},
        created_at: {template.created_at},
        updated_at : {template.updated_at}
        <br/>
    </div>
}

export function Templates() {
    const [Templates, setTemplate] = useState<any>([]);

    useEffect(
        () => {
            GraphicTemplatesService.view().then(
                (data: any) => {
                    setTemplate(data)
                }
            )
            return () => {
                setTemplate([])
            }
        }, [setTemplate]
    )

    return <div>
        Template Overview
        {
            Templates.map((data: any) => {
                return <Template key={data.id} template={data}></Template>
            })
        }
    </div>
}