import {useEffect, useState} from "react";
import {MacrosService} from "../services/analytics";

function Macro(data: any) {
    const macro = data.macro
    return <div>
        macros_id: {macro.id},
        created_at: {macro.created_at},
        updated_at : {macro.updated_at}
        <br/>
    </div>
}

export function MacroView() {
    const [Macros, setMacro] = useState<any>([]);

    useEffect(
        () => {
            MacrosService.view().then(
                (data: any) => {
                    setMacro(data)
                }
            )
            return () => {
                setMacro([])
            }
        }, [setMacro]
    )

    return <div>
        Macro Overview
        {
            Macros.map((data: any) => {
                return <Macro key={data.id} macro={data}></Macro>
            })
        }
    </div>
}