import {useEffect, useState} from "react";
import {AuditsService} from "../services/audits";

function Audit(data: any) {
    const audit = data.audit
    return <div>
        audits_id: {audit.id},
        created_at: {audit.created_at},
        updated_at : {audit.updated_at}
        <br/>
    </div>
}

export function AuditLogs() {
    const [Audits, setAudits] = useState<any>([]);

    useEffect(
        () => {
            AuditsService.view().then(
                (data: any) => {
                    setAudits(data)
                }
            )
            return () => {
                setAudits([])
            }
        }, [setAudits]
    )

    return <div>
        Audit Overview
        {
            Audits.map((data: any) => {
                return <Audit key={data.id} audit={data}></Audit>
            })
        }
    </div>
}