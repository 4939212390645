import React, {useContext} from "react";
import ProfileContext from "./context/Profile";

function UserProfile() {
    const { Profile }= React.useContext(ProfileContext);
    const _Profile: any = Profile

    return <div>
        <div>
            {_Profile.user_id_fk}<br/>
            {_Profile.id}<br/>
            {_Profile.created_at}<br/>
            {_Profile.address}<br/>
            {_Profile.verified}
            {_Profile.name}
        </div>
    </div>
}


export default UserProfile