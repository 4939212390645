import {Outlet} from "react-router-dom";

import React, {useContext, useEffect} from "react";
import SubNavContext from "../context/SubNav";

export function Overview() {

    const {setSubNav} = useContext(SubNavContext)

    useEffect(() => {
        setSubNav([
                {name: 'Users', nav: '/accounts/users'},
                {name: 'Organisations', nav: '/accounts/organisations'},
                {name: 'Audits', nav: '/accounts/audits'},
            ]
        )
        return () => {
            setSubNav([])
        }
    }, [setSubNav])

    return <Outlet/>
}