import React, {useContext, useEffect, useState} from "react";
import {ReportsService} from "../services/reports";
import SubNavContext from "../context/SubNav";
import {Outlet} from "react-router-dom";

function Report(data: any) {
    const report = data.report
    return <div>
        reports_id: {report.id},
        created_at: {report.created_at},
        updated_at : {report.updated_at}
        <br/>
    </div>
}

export function Reports() {
    const [Reports, setReport] = useState<any>([]);

    useEffect(
        () => {
            ReportsService.view().then(
                (data: any) => {
                    setReport(data)
                }
            )
            return () => {
                setReport([])
            }
        }, [setReport]
    )

    return <div>
        Report Overview
        {
            Reports.map((data: any) => {
                return <Reports key={data.id} report={data}></Reports>
            })
        }
    </div>
}