import {useEffect, useState} from "react";
import {Form} from "../common/Form";
import {AnalyticsService} from "../services/analytics";
import {Sidebar} from "../common/Sidebar";


function Analytics(data: any) {
    const config = data.config
    return <Form>
        {{
            'id': {"int": config.id},
            'Created At': {'date': config.created_at},
            'Update At': {'date': config.updated_at}
        }}
    </Form>
}

export function Settings() {
    const [AnalyticsConfigs, setAnalyticsConfig] = useState<any>([]);

    useEffect(
        () => {
            AnalyticsService.view().then(
                (data: any) => {
                    setAnalyticsConfig(data)
                }
            )
            return () => {
                setAnalyticsConfig([])
            }
        }, [setAnalyticsConfig]
    )

    const _dataComponents = AnalyticsConfigs.map((data: any) => {
        return <Analytics key={data.id} config={data}></Analytics>
    })
    return <Sidebar name={"Analytics"} entries={AnalyticsConfigs}>
        {
            _dataComponents
        }
    </Sidebar>
}