import {useEffect, useState} from "react";
import {HubsService} from "../services/hubs";
import {Form} from "../common/Form";
import {Sidebar} from "../common/Sidebar";

function Hub(data: any) {
    const hub = data.hub
    return <Form>
        {{
            'id': {"string": hub.id},
            'Created At': {'date': hub.created_at},
            'Update At': {'date': hub.updated_at}
        }}
    </Form>
}

export function Settings() {
    const [Hubs, setHub] = useState<any>([]);

    useEffect(
        () => {
            HubsService.view().then(
                (data: any) => {
                    setHub(data)
                }
            )
            return () => {
                setHub([])
            }
        }, [setHub]
    )

    return <Sidebar name={"Hubs"} entries={Hubs}>
        {
            Hubs.map((data: any) => {
                return <Hub key={data.id} hub={data}></Hub>
            })
        }
    </Sidebar>
}