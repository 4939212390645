import {useEffect, useState} from "react";
import {LocalSchedulesService} from "../services/schedules";
import {Sidebar} from "../common/Sidebar";

function Local(data: any) {
    const local = data.local
    return <div>
        locals_id: {local.id},
        created_at: {local.created_at},
        updated_at : {local.updated_at}
        <br/>
    </div>
}

export function Overview() {
    const [Locals, setLocal] = useState<any>([]);
    useEffect(
        () => {
            LocalSchedulesService.view().then(
                (data: any) => {
                    setLocal(data)
                }
            )
            return () => {
                setLocal([])
            }
        }, [setLocal]
    )

    const local = Locals.map((data: any) => {
        return <Local key={data.id} local={data}></Local>
    })

    // const routes =

    return <Sidebar entries={local}>
        {local}
    </Sidebar>
}