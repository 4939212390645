import {useEffect, useState} from "react";
import {OrganisationsService} from "../services/organisations";

function Organisation(data: any) {
    const organisation = data.organisation
    return <div>
        organisation_id: {organisation.id},
        created_at: {organisation.created_at},
        updated_at : {organisation.updated_at}
        <br/>
    </div>
}

export function Organisations() {
    const [Organisations, setOrganisations] = useState<any>([]);

    useEffect(
        () => {
            OrganisationsService.view().then(
                (data: any) => {
                    setOrganisations(data)
                }
            )
            return () => {
                setOrganisations([])
            }
        }, [setOrganisations]
    )

    return <div>
        Organisations Overview
        {
            Organisations.map((data: any) => {
                return <Organisation key={data.id} data={data}></Organisation>
            })
        }
    </div>
}