import {useEffect, useState} from "react";
import {GraphicsService} from "../services/graphics";

function Graphic(data: any) {
    const graphic = data.graphic
    return <div>
        graphics_id: {graphic.id},
        created_at: {graphic.created_at},
        updated_at : {graphic.updated_at}
        <br/>
    </div>
}

export function Graphics() {
    const [Graphics, setGraphic] = useState<any>([]);

    useEffect(
        () => {
            GraphicsService.view().then(
                (data: any) => {
                    setGraphic(data)
                }
            )
            return () => {
                setGraphic([])
            }
        }, [setGraphic]
    )

    return <div>
        Graphic Overview
        {
            Graphics.map((data: any) => {
                return <Graphic key={data.id} graphic={data}></Graphic>
            })
        }
    </div>
}