import {useEffect, useState} from "react";
import {GroupSchedulesService} from "../services/schedules";
import {Sidebar} from "../common/Sidebar";

function Group(data: any) {
    const group = data.group
    return <div>
        groups_id: {group.id},
        created_at: {group.created_at},
        updated_at : {group.updated_at}
        <br/>
    </div>
}

export function Overview() {
    const [Groups, setGroup] = useState<any>([]);

    useEffect(
        () => {
            GroupSchedulesService.view().then(
                (data: any) => {
                    setGroup(data)
                }
            )
            return () => {
                setGroup([])
            }
        }, [setGroup]
    )

    const groups = Groups.map((data: any) => {
        return <Group key={data.id} group={data}></Group>
    })
    return <Sidebar entries={groups}>
        {
            groups
        }
    </Sidebar>
}