import {Outlet} from "react-router-dom";

import React, {useContext, useEffect} from "react";
import SubNavContext from "../context/SubNav";

export function Overview() {

    const {setSubNav} = useContext(SubNavContext)

    useEffect(() => {
        setSubNav([
                {name: 'Overview', nav: '/sites/overview'},
                {name: 'Alerts', nav: '/sites/alerts'},
                {name: 'Equipments', nav: '/sites/equipments'},
            ]
        )
        return () => {
            setSubNav([])
        }
    }, [setSubNav])

    return <Outlet/>
}