import {FormControl, FormLabel, Input, Stack} from "@chakra-ui/react";
import {forOwn} from "lodash";

export function Form(props: any) {
    const identifier = props.children.id.int
    let _form: any[] = []

    forOwn(props.children, function(value, key) {
        forOwn(value, function (v, _type) {
            switch (_type) {
                case 'date': {
                    const _key = identifier + v + key
                    const label = <FormLabel>{key}</FormLabel>
                    const input = <Input placeholder={v}></Input>
                    const elem = <FormControl key={_key}>{label} {input}</FormControl>
                    _form.push(elem)
                    break;
                }
                case 'int': {
                    const _key = identifier + v + key
                    const label = <FormLabel>{key}</FormLabel>
                    const input = <Input placeholder={v}></Input>
                    const elem = <FormControl key={_key}>{label} {input}</FormControl>
                    _form.push(elem)
                    break;
                }
                case 'string': {
                    const _key = identifier + v + key
                    const label = <FormLabel>{key}</FormLabel>
                    const input = <Input placeholder={v}></Input>
                    const elem = <FormControl key={_key}>{label} {input}</FormControl>
                    _form.push(elem)
                    break;
                }
            }
        })
    })
    return <div>
        <Stack>
            {_form}
        </Stack>
    </div>
}