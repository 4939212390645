import {useEffect, useState} from "react";
import {SitesService} from "../services/sites";
import {Sidebar} from "../common/Sidebar";
import {Form} from "../common/Form";

function Site(data: any) {
    const site = data.site
    return <Form>
        {{
            'id': {'string': site.id},
            'Created At': {'date': site.created_at},
            'Update At': {'date': site.updated_at}
        }}
    </Form>
}

export function Sites() {
    const [Sites, setSites] = useState<any>([]);

    useEffect(
        () => {
            SitesService.view().then(
                (data) => {
                    setSites(data)
                }
            )
            return () => {
                setSites([])
            }
        }, [setSites]
    )

    const _dataComponents = Sites.map((data: any) => {
        return <Site key={data.id} site={data}></Site>
    })

    return <Sidebar name={"Sites"} entries={Sites}>
        {_dataComponents}
    </Sidebar>
}