import React, {useEffect, useMemo, useState} from "react";
import UserContext from "../../context";
import SubNavContext from "../iot/context/SubNav";
import {AuthService} from "../../services/AuthService";
import {Outlet} from "react-router-dom";
import SidebarWithHeader from "./Sidebar";
import {LinkItemProps} from "./Sidebar"
import {
    FiCompass, FiHome, FiSettings,
    FiStar, FiTrendingUp, FiCalendar,
    FiAlertOctagon, FiPaperclip, FiGrid, FiTool
} from "react-icons/fi";

const LinkItems: Array<LinkItemProps> = [
    {icon: FiHome, name: "sites"},
    {icon: FiTrendingUp, name: "hubs"},
    {icon: FiCompass, name: "analytics"},
    {icon: FiStar, name: "charting"},
    {icon: FiGrid, name: "graphics"},
    {icon: FiPaperclip, name: "reports"},
    {icon: FiAlertOctagon, name: "notifications"},
    {icon: FiCalendar, name: "schedules"},
    {icon: FiSettings, name: "accounts"},
    {icon: FiTool, name: "support"},
];

export function Dashboard() {
    const [SubNav, setSubNav] = useState([])
    const {setUser} = React.useContext(UserContext)
    const logout = async () => {
        await AuthService.logout()
        setUser({})
    }

    const navSetters = {SubNav, setSubNav}

    return <SubNavContext.Provider value={navSetters}>
        <SidebarWithHeader items={LinkItems} signout={logout}>
            <Outlet/>
        </SidebarWithHeader>
    </SubNavContext.Provider>
}

