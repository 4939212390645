import UserProfile from "./Profile";
import React, {useContext, useEffect, useState} from "react";
import {
    Routes, Route, Link, Outlet
} from "react-router-dom";

import {Overview as AccountsOverview} from "./accounts/Overview";
import {Overview as SitesOverview} from "./sites/Overview";
import {Overview as AnalyticsOverview} from "./analytics/Overview";
import {Overview as ChartingOverview} from "./charting/Overview";
import {Overview as HubsOverview} from "./hubs/Overview";
import {Overview as GraphicsOverview} from "./graphics/Overview";
import {Overview as ReportsOverview} from "./reports/Overview";
import {Overview as NotificationsOverview} from "./notifications/Overview";
import {Overview as SchedulesOverview} from "./schedules/Overview";
import {Dashboard} from "./Dashboard";

import ProfileContext from "./context/Profile";
import SubNavContext from "./context/SubNav";
import {ProfilesService} from "./services/accounts";
import UserContext from "../../context";
import {Support} from "./Support";
import {Overview as Local} from "./schedules/Local";
import {Overview as Groups} from "./schedules/Groups";
import {Overview as Exception} from "./schedules/Exception";
import {Reports} from "./reports/Reports";
import {Builder as ReportBuilder} from "./reports/Builder";
import {Settings as HubSettings} from "./hubs/Settings";
import {Provision} from "./hubs/Provision";
import {Settings as AnalyticsSettings} from "./analytics/Settings";
import {LogicView} from "./analytics/Logic";
import {MacroView} from "./analytics/Macros";
import {Graphics} from "./graphics/Graphics";
import {Templates} from "./graphics/Templates";
import {Builder as GraphicBuilder} from "./graphics/Builder";
import {AuditLogs} from "./accounts/AuditLogs";
import {Organisations} from "./accounts/Organisations";
import {Alerts} from "./sites/Alerts";
import {Sites} from "./sites/Site";
import {Equipments} from "./sites/Equipments";

export function IoT() {

    const [Profile, setProfile] = useState({})
    const [SubNav, setSubNav] = useState([])
    const {user}: { user: any } = useContext(UserContext)

    const fetchProfile = async () => {
        const profile = await ProfilesService.get(user.id)
        return profile!
    }

    useEffect(
        () => {
            fetchProfile().then(
                (user_profile: any) => {
                    setProfile(user_profile)
                }
            )
            return () => {
                setProfile({})
            }
        }, []
    )

    return <ProfileContext.Provider value={{Profile, setProfile}}>
        <SubNavContext.Provider value={{SubNav, setSubNav}}>
            <Routes>
                <Route path="/" element={<Dashboard/>}>
                    <Route index element={<SitesOverview/>}></Route>
                    <Route path="sites" element={<SitesOverview/>}>
                        <Route index element={<Sites/>}></Route>
                        <Route path="overview" element={<Sites/>}/>
                        <Route path="alerts" element={<Alerts/>}/>
                        <Route path="equipments" element={<Equipments/>}/>
                    </Route>
                    <Route path="analytics" element={<AnalyticsOverview/>}>
                        <Route index element={<AnalyticsSettings/>}/>
                        <Route path="settings" element={<AnalyticsSettings/>}/>
                        <Route path="logic" element={<LogicView/>}/>
                        <Route path="macro" element={<MacroView/>}/>
                    </Route>
                    <Route path="charting" element={<ChartingOverview/>}/>
                    <Route path="hubs" element={<HubsOverview/>}>
                        <Route index element={<HubSettings/>}/>
                        <Route path="settings" element={<HubSettings/>}/>
                        <Route path="provision" element={<Provision/>}/>
                    </Route>
                    <Route path="graphics" element={<GraphicsOverview/>}>
                        <Route index element={<Graphics/>}></Route>
                        <Route path="settings" element={<Graphics/>}/>
                        <Route path="templates" element={<Templates/>}/>
                        <Route path="builder" element={<GraphicBuilder/>}/>
                    </Route>
                    <Route path="reports" element={<ReportsOverview/>}>
                        <Route index element={<Reports/>}/>
                        <Route path="settings" element={<Reports/>}/>
                        <Route path="builder" element={<ReportBuilder/>}/>
                    </Route>
                    <Route path="notifications" element={<NotificationsOverview/>}/>
                    <Route path="schedules" element={<SchedulesOverview/>}>
                        <Route index element={<Local/>}/>
                        <Route path="local" element={<Local/>}/>
                        <Route path="group" element={<Groups/>}/>
                        <Route path="exception" element={<Exception/>}/>
                    </Route>
                    <Route path="profile" element={<UserProfile/>}/>
                    <Route path="accounts" element={<AccountsOverview/>}>
                        <Route index element={<UserProfile/>}></Route>
                        <Route path="users" element={<UserProfile/>}/>
                        <Route path="organisations" element={<Organisations/>}/>
                        <Route path="audits" element={<AuditLogs/>}/>
                    </Route>
                    <Route path="support" element={<Support/>}/>
                </Route>
            </Routes>
        </SubNavContext.Provider>
    </ProfileContext.Provider>
}
