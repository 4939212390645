import React, {useState} from "react";
import {AuthService} from "../services/AuthService"
import UserContext from "../context";

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';


function Auth() {

    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');

    const {setUser} = React.useContext(UserContext);

    const [FormView, setFormView] = useState('login');


    const submitValue = async (view: string) => {
        const data = view === 'login' ? await AuthService.login(Username, Password) : await AuthService.register(Username, Password)
        console.log(data.user);
        setUser(data.user!);
    }

    let button, mode, heading;
    if (FormView === 'login') {
        button = <Button onClick={() => submitValue('login')}
                         bg={'blue.400'}
                         color={'white'}
                         _hover={{
                             bg: 'blue.500',
                         }}>
            Sign in
        </Button>
        mode = <Link onClick={() => setFormView("register")} color={'blue.400'}>Register?</Link>
        heading = "Sign in to your account"
    } else {
        button = <Button onClick={() => submitValue('register')}
                         bg={'blue.400'}
                         color={'white'}
                         _hover={{
                             bg: 'blue.500',
                         }}>
            Register
        </Button>
        mode = <Link onClick={() => setFormView("login")} color={'blue.400'}>Login?</Link>
        heading = "Register for a new account"
    }

    return <div>
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>{heading}</Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                        to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <FormControl id="email">
                            <FormLabel>Username</FormLabel>
                            <Input type="email" onChange={e => setUsername(e.target.value)}/>
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" onChange={e => setPassword(e.target.value)}/>
                        </FormControl>
                        <Stack spacing={10}>
                            <Stack
                                direction={{base: 'column', sm: 'row'}}
                                align={'start'}
                                justify={'space-between'}>
                                {/*<Checkbox>Remember me</Checkbox>*/}
                                {mode}
                                <Link color={'blue.400'}>Forgot password?</Link>
                            </Stack>
                            {button}
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    </div>
}

export default Auth;