import {useEffect, useState} from "react";
import {NotificationsService} from "../services/notifications";
import {Form} from "../common/Form";
import {Sidebar} from "../common/Sidebar";

function Notification(data: any) {
    const config = data.notification
    return <Form>
        {{
            'id': {"int": config.id},
            'Created At': {'date': config.created_at},
            'Update At': {'date': config.updated_at}
        }}
    </Form>
}

export function Overview() {
    const [Notifications, setHub] = useState<any>([]);

    useEffect(
        () => {
            NotificationsService.view().then(
                (data) => {
                    setHub(data)
                }
            )
            return () => {
                setHub([])
            }
        }, [setHub]
    )

    return <Sidebar overview={"Notifications"} entries={Notifications}>
        {
            Notifications.map((data: any) => {
                return <Notification key={data.id} notification={data}></Notification>
            })
        }
    </Sidebar>
}