import {useEffect, useState} from "react";
import {ExceptionScheduleService} from "../services/schedules";
import {Sidebar} from "../common/Sidebar";

function Exception(data: any) {
    const exception = data.exception
    return <div>
        exceptions_id: {exception.id},
        created_at: {exception.created_at},
        updated_at : {exception.updated_at}
        <br/>
    </div>
}

export function Overview() {
    const [Exceptions, setException] = useState<any>([]);

    useEffect(
        () => {
            ExceptionScheduleService.view().then(
                (data: any) => {
                    setException(data)
                }
            )
            return () => {
                setException([])
            }
        }, [setException]
    )

    const exceptions = Exceptions.map((data: any) => {
        return <Exception key={data.id} exception={data}></Exception>
    })

    return <Sidebar entries={exceptions}>
        {exceptions}
    </Sidebar>
}