import {useEffect, useState} from "react";
import {LogicsService} from "../services/analytics";

function Logic(data: any) {
    const hub = data.hub
    return <div>
        hubs_id: {hub.id},
        created_at: {hub.created_at},
        updated_at : {hub.updated_at}
        <br/>
    </div>
}

export function LogicView() {
    const [Logics, setLogic] = useState<any>([]);

    useEffect(
        () => {
            LogicsService.view().then(
                (data: any) => {
                    setLogic(data)
                }
            )
            return () => {
                setLogic([])
            }
        }, [setLogic]
    )

    return <div>
        Logic Overview
        {
            Logics.map((data: any) => {
                return <Logic key={data.id} hub={data}></Logic>
            })
        }
    </div>
}