import React, {useEffect, useState} from 'react';
import './App.css';
import UserContext from "./context";
import Auth from "./common/Auth";
import {IoT} from "./features/iot/IoT"
import {isEmpty, isNull} from "lodash";
import {supabase} from "./services/Supabase";

async function getCurrentUser() {
    const { data: { user } } = await supabase.auth.getUser()
    return user
}

function App() {
    const [user, setUser] = useState({});
    useEffect(
        () => {
            getCurrentUser().then(
                (value) => {
                    const current_user = isNull(value) ? {} : value
                    setUser(current_user)
                }
            )
            return () => {
                setUser({})
            }
        }, []
    )
    return (
        <UserContext.Provider value={{user, setUser}}>
            <div className="App">
                <Layout></Layout>
            </div>
        </UserContext.Provider>

    );
}

function Layout() {
    const value = React.useContext(UserContext);
    return <div>
        {isEmpty(value.user) ? <Auth/> : <IoT/>}
    </div>
}

export default App;
