import {supabase} from "../../../services/Supabase";


export const AnalyticsService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('analytics')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('analytics')
            .select('*')
        return data
    },

    create: async function(analytics: any) {
        const { data, error } = await supabase
            .from('analytics')
            .insert([
                { id: analytics.id },
            ])
        return data
    },

    update: async function(id: string, analytics: any) {
        const { data, error } = await supabase
            .from('analytics')
            .update([
                { ...analytics },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('analytics')
            .delete()
            .eq('id', id)
        return data
    }
}

export const LogicsService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('analytics_logic')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('analytics_logic')
            .select('*')
        return data
    },

    create: async function(logic: any) {
        const { data, error } = await supabase
            .from('analytics_logic')
            .insert([
                { id: logic.id },
            ])
        return data
    },

    update: async function(id: string, logic: any) {
        const { data, error } = await supabase
            .from('analytics_logic')
            .update([
                { ...logic },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('analytics_logic')
            .delete()
            .eq('id', id)
        return data
    }
}

export const MacrosService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('analytics_macro')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('analytics_macro')
            .select('*')
        return data
    },

    create: async function(macro: any) {
        const { data, error } = await supabase
            .from('analytics_macro')
            .insert([
                { id: macro.id },
            ])
        return data
    },

    update: async function(id: string, macro: any) {
        const { data, error } = await supabase
            .from('analytics_macro')
            .update([
                { ...macro },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('analytics_macro')
            .delete()
            .eq('id', id)
        return data
    }
}