import {supabase} from "../../../services/Supabase";

export const GraphicsService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('graphics')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('graphics')
            .select('*')
        return data
    },

    create: async function(graphic: any) {
        const { data, error } = await supabase
            .from('graphics')
            .insert([
                { id: graphic.id },
            ])
        return data
    },

    update: async function(id: string, graphic: any) {
        const { data, error } = await supabase
            .from('graphics')
            .update([
                { ...graphic },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('graphics')
            .delete()
            .eq('id', id)
        return data
    }
}


export const GraphicTemplatesService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('graphic_templates')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('graphic_templates')
            .select('*')
        return data
    },

    create: async function(template: any) {
        const { data, error } = await supabase
            .from('graphic_templates')
            .insert([
                { id: template.id },
            ])
        return data
    },

    update: async function(id: string, template: any) {
        const { data, error } = await supabase
            .from('graphic_templates')
            .update([
                { ...template },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('graphic_templates')
            .delete()
            .eq('id', id)
        return data
    }
}