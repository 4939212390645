import {Box, Heading, VStack, Link, Flex} from "@chakra-ui/react";
import {forEach} from "lodash";
import {useEffect, useState} from "react";

function ItemCard(props: any, ...rest: any) {
    const config = props.config
    const active = props.active
    return <Flex width={'100%'} shadow='md' borderWidth='1px' {...rest} backgroundColor={active && "#8abbc7"}>
        <Link onClick={() => props.onClick(config.id)} mt={4}>
            <Heading fontSize='xl'>{config.name}</Heading>
        </Link>
    </Flex>
}

export function Sidebar(props: any) {
    const _default = props.children[0]
    const [Item, setItem] = useState<any>(_default)

    useEffect(() => {
        setItem(Item || _default)
    }, [Item, _default])

    let data: any = {}
    forEach(props.children, (val: any) => {
        data[val.key] = val
    })

    const _entries = props.entries || []
    const items = _entries.map((val: any) => {
        return <ItemCard
            key={val.id}
            config={val}
            onClick={(val: any) => setItem(data[val])}
            active={Item?.key == val.id}
        />
    })

    return <Flex h='100%' width={'100%'} backgroundColor={"blue"}>
        <Flex p={1.5} width={'30%'} backgroundColor={"tomato"}>
            <VStack width={'100%'} backgroundColor={"purple"}>
                <Box width={'100%'}
                     overflowY="auto"
                     css={{
                         '&::-webkit-scrollbar': {
                             width: '4px',
                         },
                         '&::-webkit-scrollbar-track': {
                             width: '6px',
                         },
                         '&::-webkit-scrollbar-thumb': {
                             borderRadius: '24px',
                         },
                     }}
                >
                    {items}
                </Box>
            </VStack>
        </Flex>
        <Flex width={'70%'} backgroundColor={"violet"} p={2.5}>
            {Item}
        </Flex>
    </Flex>
}