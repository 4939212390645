import {Link, Tab, TabList, Tabs} from "@chakra-ui/react";
import {Link as ReactRouter} from 'react-router-dom';

export function Overview(props: any) {
    const navs = props.nav.map((nav: {
        name: any;
        nav: any;
    }) => {
        return <Tab key={nav.nav}>
            <Link as={ReactRouter} to={nav.nav}>
                {nav.name}
            </Link>
        </Tab>
    })
    console.log(navs)

    return <Tabs variant='soft-rounded' colorScheme='green'>
        <TabList>
            {navs}
        </TabList>
    </Tabs>
}


