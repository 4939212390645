import {Outlet} from "react-router-dom";

import React, {useContext, useEffect} from "react";
import SubNavContext from "../context/SubNav";

export function Overview() {

    const {setSubNav} = useContext(SubNavContext)

    useEffect(() => {
        setSubNav([
                {name: 'Settings', nav: '/analytics/settings'},
                {name: 'Logic', nav: '/analytics/logic'},
                {name: 'Macros', nav: '/analytics/macros'}
            ]
        )
        return () => {
            setSubNav([])
        }
    }, [setSubNav])

    return <Outlet/>
}