import {Outlet} from "react-router-dom";

import React, {useContext, useEffect} from "react";
import SubNavContext from "../context/SubNav";

export function Overview() {

    const {setSubNav} = useContext(SubNavContext)

    useEffect(() => {
        setSubNav([
                {name: 'Settings', nav: '/hubs/settings'},
                {name: 'Provision', nav: '/hubs/provision'},
            ]
        )
        return () => {
            setSubNav([])
        }
    }, [setSubNav])

    return <Outlet/>
}