import {Outlet} from "react-router-dom";

import React, {useContext, useEffect} from "react";
import SubNavContext from "../context/SubNav";

export function Overview() {

    const {setSubNav} = useContext(SubNavContext)

    useEffect(() => {
        setSubNav([
                {name: 'Local', nav: '/schedules/local'},
                {name: 'Groups', nav: '/schedules/group'},
                {name: 'Exception', nav: '/schedules/exception'}
            ]
        )
        return () => {
            setSubNav([])
        }
    }, [setSubNav])

    return <Outlet/>
}