import {useEffect, useState} from "react";
import {ChartingService} from "../services/charting";

function Charting(data: any) {
    const charting = data.charting
    return <div>
        charting_id: {charting.id},
        created_at: {charting.created_at},
        updated_at : {charting.updated_at}
        <br/>
    </div>
}

export function Overview() {
    const [ChartingCfg, setCharting] = useState<any>([]);

    useEffect(
        () => {
            ChartingService.view().then(
                (data: any) => {
                    setCharting(data)
                }
            )
            return () => {
                setCharting([])
            }
        }, [setCharting]
    )

    return <div>
        Charting Overview
        {
            ChartingCfg.map((data: any) => {
                return <Charting key={data.id} charting={data}></Charting>
            })
        }
    </div>
}