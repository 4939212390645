import {Profile} from "./models/Profile";

import {supabase} from "../../../services/Supabase";

export const UsersService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('users')
            .select('*')
            .eq("id", id)
        return data
    },


    view: async function() {
        let { data, error } = await supabase
            .from('users')
            .select('*')
        return data
    },

    create: async function(site: any) {
        const { data, error } = await supabase
            .from('users')
            .insert([
                { id: site.id },
            ])
        return data
    },

    update: async function(id: string, site: any) {
        const { data, error } = await supabase
            .from('users')
            .update([
                { ...site },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('users')
            .delete()
            .eq('id', id)
        return data
    }
}

export const ProfilesService = {
    get: async function(id: string) {
        let { data, error } = await supabase
            .from('profile')
            .select('*')
            .eq("user_id_fk", id)
        return data![0]
    },


    view: async function() {
        let { data, error } = await supabase
            .from('profile')
            .select('*')
        return data
    },

    create: async function(site: any) {
        const { data, error } = await supabase
            .from('profile')
            .insert([
                { id: site.id },
            ])
        return data
    },

    update: async function(id: string, site: any) {
        const { data, error } = await supabase
            .from('profile')
            .update([
                { ...site },
            ])
            .eq('id', id)
        return data
    },

    remove: async function(id: string)  {
        const { data, error } = await supabase
            .from('profile')
            .delete()
            .eq('id', id)
        return data
    }
}